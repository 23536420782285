import { required, regex, confirmed, is, is_not } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import i18n from "@/i18n";

import { setInteractionMode } from "vee-validate";

setInteractionMode("eager", (context) => {
  if (!context.value) {
    return {
      on: ["change"],
    };
  }
  return { on: ["change", "blur", "input"] };
});

extend("mandatory", {
  ...required,
  params: ["type"],
  validate(value, { type }) {
    if (["MA", "FU", "R", "LL", "MAM"].includes(type)) {
      return {
        required: true,
        valid: typeof value === "object" && value.length > 0,
      };
    }

    return {
      required: true,
      valid: ["", null, undefined].indexOf(value) === -1,
    };
  },
  message: (_, values) => {
    if (values.type === 'C' && i18n.te(`validations.messages.${values.type}.mandatory`)) {
      return i18n.t(`validations.messages.${values.type}.mandatory`, values)
    }
    return i18n.t("validations.messages.mandatory", values);
  }
});

extend("between", {
  params: ["type", "min", "max"],
  validate(value, { type, min, max }) {
    if (["MA", "FU", "R", "LL"].includes(type)) {
      return (
        typeof value === "object" && value.length >= min && value.length <= max
      );
    }
    if (["NI", "MI"].includes(type)) {
      return (
        typeof value === "string" &&
        parseFloat(value) >= min &&
        parseFloat(value) <= max
      );
    }
    return true;
  },
  message: (_, values) =>
    i18n.te(`validations.messages.${values.type}.between`)
      ? i18n.t(`validations.messages.${values.type}.between`, values)
      : i18n.t("validations.messages.between", values),
});

extend("min", {
  params: ["type", "min"],
  validate(value, { type, min }) {
    if (["MA", "FU", "LL", "R"].includes(type)) {
      return typeof value === "object" && value.length >= min;
    }
    if (["NI", "MI"].includes(type)) {
      return typeof value === "string" && parseFloat(value) >= min;
    }
    return true;
  },
  message: (_, values) =>
    i18n.te(`validations.messages.${values.type}.min`)
      ? i18n.t(`validations.messages.${values.type}.min`, values)
      : i18n.t("validations.messages.min", values),
});

extend("max", {
  params: ["type", "max"],
  validate(value, { type, max }) {
    if (["MA", "FU", "LL", "R"].includes(type)) {
      return typeof value === "object" && value.length <= max;
    }
    if (["NI", "MI"].includes(type)) {
      return typeof value === "string" && parseFloat(value) <= max;
    }
    return true;
  },
  message: (_, values) =>
    i18n.te(`validations.messages.${values.type}.max`)
      ? i18n.t(`validations.messages.${values.type}.max`, values)
      : i18n.t("validations.messages.max", values),
});

extend("min_length", {
  params: ["type", "min"],
  validate(value, { type, min }) {
    if (type === "MI") {
      return typeof value === "string" && value.length >= min;
    }
    return true;
  },
  message: (_, values) => i18n.t("validations.messages.min_length", values),
});

extend("max_length", {
  params: ["type", "max"],
  validate(value, { type, max }) {
    if (type === "MI") {
      return typeof value === "string" && value.length <= max;
    }
    return true;
  },
  message: (_, values) => i18n.t("validations.messages.max_length", values),
});

extend("total", {
  ...required,
  params: ["total", "totalValueCurrent"],
  validate(value, { total, totalValueCurrent }) {

    return {
      required: parseInt(totalValueCurrent) !== total,
      valid: parseInt(totalValueCurrent) === total,
    };

  },
  message: (_, values) => i18n.t("validations.messages.MI.total", values),
});

extend("credential", {
  ...required,
  params: ["response_id","sid", "username", "password", "required"],
  async validate(value, {response_id, sid, username, password, required }) {
    if (!required && !username && !password) {
      return {
        required: true,
        valid: true
      };
    }
    if (!username || !password) {
      return {
        required: false,
        valid: false
      };
    }
    const valid = await fetch(`${process.env.VUE_APP_RESPONSE_API}/v1/surveys/${sid}/credentials`, {
      method: "post",
      headers: {
        "Accept": "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        response_id,
        username,
        password,
      })
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong');
    }).then(() => true).catch(() => false);

    return {
      required: true,
      valid
    };
  },
  message: (_, values) => i18n.t("validations.messages.credential", values),
});

extend('regex', regex);
extend('is', is);
extend('is_not', is_not);
extend('confirmed', confirmed);
extend("confirmed_with_msg", {
  ...confirmed,
  params: ["target", "msg"],
  message: (_, values) => values.msg,
});