import Vue from 'vue'
import Vuex from 'vuex'
import { back, startSurvey, submit, screenOut, login, quotaFull, submitPreview, preview } from '@/api/response'
import { checkConditions } from '@/utils/question'
import { handleCarryForwardOption } from '@/utils/question'
import { difference, isEqual } from 'lodash'
import { updateResponse } from '../api/response'

Vue.use(Vuex)

export const SET_CONNECTED = 'SET_CONNECTED'
export const SET_LOADING = 'SET_LOADING'
export const SET_SURVEY = 'SET_SURVEY'
export const SET_PAGE = 'SET_PAGE'
export const SET_RESPONSE = 'SET_RESPONSE'
export const UPDATE_STATE = 'UPDATE_STATE'
export const UPDATE_CHANGES = 'UPDATE_CHANGES'
export const UPDATE_CHANGE_ACTION = 'UPDATE_CHANGE_ACTION'
export const SET_PREVIEWED_QUESTIONS = 'SET_PREVIEWED_QUESTIONS'

export const START_SURVEY = 'startSurvey'
export const BACK = 'BACK'
export const NEXT = 'NEXT'
export const SCREEN_OUT = 'SCREEN_OUT'
export const QUOTA_FULL = 'QUOTA_FULL'
export const LOGIN = 'LOGIN'
export const PREVIEW_SURVEY = 'PREVIEW_SURVEY'
export const UPDATE_RESPONSE = 'UPDATE_RESPONSE'
export const SUBMIT_PREVIEW = 'SUBMIT_PREVIEW'

export default new Vuex.Store({
  state: {
    connected: navigator.onLine,
    loading: {},
    survey: {},
    page: {},
    response: {},
    previewedQuestions: {}
  },
  getters: {
    connected: (state) => state.connected,
    loading: (state) => (code) => state.loading[code] || false,
    survey: (state) => (code) => state.survey[code],
    page: (state) => (code) => state.page[code],
    questions: (state, getters) => (code) => {
      const page = getters.page(code)
      let questions = page?.questions
      if (!page && !questions) return []
			// Filter all hidden questions from result
			let beforeResult, afterResult = getters.result(code)
			do {
				beforeResult = afterResult
				questions = questions.filter(question => checkConditions(question.conditions, beforeResult))
				afterResult = Object.fromEntries(
					Object.entries(beforeResult).filter(
						([key]) =>
							!difference(page?.questions, questions)
								.map((q) => q.qid)
								.includes(+key.split('_')[0]),
					),
				)
			} while (!isEqual(beforeResult, afterResult))
      return questions.filter(question => handleCarryForwardOption(question, getters.result(code)))
    },
    response: (state) => (code) => state.response[code],
    previousStates: (state, getters) => (code) => getters.response(code)?.previous_states || {},
    currentState: (state, getters) => (code) => getters.response(code)?.current_state || {},
    hiddenData: (state, getters) => (code) => {
      const response = getters.response(code)
      const hidden = response?.hidden_data || {}
      const {_id, uuid, rid, result_qcode} = response

      return {
        ...hidden,
        ["id"]: _id,
        ["uuid"]: uuid,
        ["rid"]: rid,
        ...result_qcode
      };

    },
    result: (state, getters) => (code) => {
      const response = getters.response(code)
      return {
        username: response?.username || '',
        ...response?.result,
        ...response?.current_state?.changes,
      }
    },
    resultWithQCode: (state, getters) => (code) => {
      const response = getters.response(code)
      return {
        ...response?.result_qcode,
      }
    },
    previewedQuestions: (state) => (code) => state.previewedQuestions[code],
  },
  mutations: {
    [SET_CONNECTED] (state, payload) {
      state.connected = payload
    },
    [SET_LOADING] (state, { code, loading }) {
      Vue.set(state.loading, code, loading)
    },
    [SET_SURVEY] (state, survey) {
      Vue.set(state.survey, survey.code, survey)
    },
    [SET_PAGE] (state, { code, page }) {
      Vue.set(state.page, code, page)
    },
    [SET_RESPONSE] (state, { code, response }) {
      Vue.set(state.response, code, response)
    },
    [UPDATE_STATE] (state, { code, current_state }) {
      const oldState = state.response[code]?.current_state
      Vue.set(state.response[code], 'current_state', { ...oldState, ...current_state, })
    },
    [UPDATE_CHANGES] (state, { code, changes }) {
      const currentChanges = state.response[code]?.current_state?.changes
      Vue.set(state.response[code].current_state, 'changes', { ...currentChanges, ...changes })
    },
    [SET_PREVIEWED_QUESTIONS] (state, { code, questions }) {
      Vue.set(state.previewedQuestions, code, questions)
    }
  },
  actions: {
    async [START_SURVEY] ({ commit }, { code, ...params }) {
      const {
        data: { data },
      } = await startSurvey(code, params)
      const { survey, page, response } = data
      commit(SET_SURVEY, survey)
      commit(SET_PAGE, { code, page })
      commit(SET_RESPONSE, { code, response })
    },
    async [BACK] ({ commit }, { code, data }) {
      commit(SET_LOADING, { code, loading: true })
      try {
        commit(UPDATE_CHANGES, { code, changes: data?.result })
        const {
          data: { data: surveyData },
        } = await back(code, data)
        const { survey, page, response } = surveyData
        commit(SET_SURVEY, survey)
        commit(SET_PAGE, { code, page })
        commit(SET_RESPONSE, { code, response })
      } finally {
        commit(SET_LOADING, { code, loading: false })
      }
    },
    async [NEXT] ({ commit }, { code, data }) {
      commit(SET_LOADING, { code, loading: true })
      try {
        commit(UPDATE_CHANGES, { code, changes: data?.result || {} })
        const {
          data: { data: surveyData },
        } = await submit(code, data)
        const { survey, page, response } = surveyData
        commit(SET_SURVEY, survey)
        commit(SET_PAGE, { code, page })
        commit(SET_RESPONSE, { code, response })
      } finally {
        commit(SET_LOADING, { code, loading: false })
      }
    },
    async [SCREEN_OUT] ({ commit }, { code, data }) {
      commit(SET_LOADING, { code, loading: true })
      try {
        commit(UPDATE_CHANGES, { code, changes: data?.result || {} })
        const {
          data: { data: surveyData },
        } = await screenOut(code, data)
        const { survey, response } = surveyData
        commit(SET_SURVEY, survey)
        commit(SET_RESPONSE, { code, response })
      } finally {
        commit(SET_LOADING, { code, loading: false })
      }
    },
    async [QUOTA_FULL] ({ commit }, { code, data }) {
      commit(SET_LOADING, { code, loading: true })
      try {
        commit(UPDATE_CHANGES, { code, changes: data?.result || {} })
        const {
          data: { data: surveyData },
        } = await quotaFull(code, data)
        const { survey, response } = surveyData
        commit(SET_SURVEY, survey)
        commit(SET_RESPONSE, { code, response })
      } finally {
        commit(SET_LOADING, { code, loading: false })
      }
    },
    async [LOGIN] ({ commit }, { code, data }) {
      commit(SET_LOADING, { code, loading: true })
      try {
        commit(UPDATE_CHANGES, { code, changes: data?.result || {} })
        const {
          data: { data: surveyData },
        } = await login(code, data)
        const { survey, page, response } = surveyData
        commit(SET_SURVEY, survey)
        commit(SET_PAGE, { code, page })
        commit(SET_RESPONSE, { code, response })
      } finally {
        commit(SET_LOADING, { code, loading: false })
      }
    },
    async [PREVIEW_SURVEY] ({ commit }, { code, data }) {
      commit(SET_LOADING, { code, loading: true })
      try {
        const {
          data: { data: surveyData },
        } = await preview(code, data)
        const { survey, questions, response } = surveyData
        commit(SET_RESPONSE, { code, response })
        commit(SET_PREVIEWED_QUESTIONS, { code, questions })
        commit(SET_SURVEY, survey)
      } finally {
        commit(SET_LOADING, { code, loading: false })
      }
    },
    async [SUBMIT_PREVIEW] ({ commit }, { code, data }) {
      commit(SET_LOADING, { code, loading: true })
      try {
        const {
          data: { data: surveyData },
        } = await submitPreview(code, data)
        const { survey, page, response } = surveyData
        commit(SET_SURVEY, survey)
        commit(SET_PAGE, { code, page })
        commit(SET_RESPONSE, { code, response })
      } finally {
        commit(SET_LOADING, { code, loading: false })
      }
    },
    async [UPDATE_RESPONSE] ({ commit }, { code, data }) {
      commit(SET_LOADING, { code, loading: true })
      try {
        const {
          data: { data: surveyData },
        } = await updateResponse(code, data)
        const { survey, questions, response, nextQuestions } = surveyData
        commit(SET_SURVEY, survey)
        commit(SET_PREVIEWED_QUESTIONS, { code, questions })
        commit(SET_RESPONSE, { code, response })
        return Promise.resolve(nextQuestions)
      } finally {
        commit(SET_LOADING, { code, loading: false })
      }
    },
  },
})
