import { MULTIPLE_ANSWER, MULTIPLE_ANSWER_MATRIX, SINGLE_ANSWER_MATRIX, MULTIPLE_INPUT, RANKING, CREDENTIAL} from '@/constants/question-type'
import {getQuestion} from '@/api/response'

const OR = 'OR'
const AND = 'AND'
const Equal = '='
const NotEqual = '!='
const Contains = 'contains'
const NotContains = 'not contains'

export const questionKey = (qid, sqcode, acode) => {
  const keyArray = [qid]
  if (sqcode || acode) keyArray.push(sqcode, acode)
  return keyArray.join('_')
}

export const evaluateCondition = (a, b, operator) => {
  switch (operator) {
    case Equal:
      return a == b
    case NotEqual:
      return a != b
    case Contains:
      a = `${a}`.toLowerCase()
      b = `${b}`.toLowerCase()
      return a.includes(b)
    case NotContains:
      a = `${a}`.toLowerCase()
      b = `${b}`.toLowerCase()
      return !a.includes(b)
  }
}

export const checkConditions = (conditions, result) => {
  if (!conditions || typeof conditions !== 'object') return true

  if (conditions.qid) {
    let key = questionKey(conditions.qid), value = conditions.value
    switch (conditions.type) {
      case MULTIPLE_ANSWER:
        key = questionKey(conditions.qid, null, conditions.value)
        value = '1'
        break
      case SINGLE_ANSWER_MATRIX:
      case MULTIPLE_INPUT:
          key = questionKey(conditions.qid, conditions.sqcode, null)
          break
      case MULTIPLE_ANSWER_MATRIX:
          key = questionKey(conditions.qid, conditions.sqcode, conditions.value)
          value = '1'
          break
      case CREDENTIAL:
        key = 'username'
        break
    }
    return evaluateCondition(result[key], value, conditions.operator)
  }

  if (conditions?.logic && conditions?.rules) {
    switch (conditions.logic) {
      case OR:
        for (let i = 0; i < conditions.rules.length; i++) {
          if (checkConditions(conditions.rules[i], result)) {
            return true
          }
        }
        return false
      case AND:
        for (let i = 0; i < conditions.rules.length; i++) {
          if (!checkConditions(conditions.rules[i], result)) {
            return false
          }
        }
        return true
      default:
        return true
    }
  }

  return true
}

export const handleCarryForwardOption = (question, result) => {
  const carryForward = question?.options?.carry_forward
  const types = [SINGLE_ANSWER_MATRIX, MULTIPLE_INPUT, RANKING, MULTIPLE_ANSWER_MATRIX]
  if (carryForward?.is_enabled) {
    const qid = carryForward.config?.qid
    const logic = carryForward.config?.logic

    Promise.resolve(getQuestion(qid)).then(response => {
      const {data: {data: questionRf}} = response
      const answerCodesRf = questionRf?.answers.map(answer => answer.code)
      const answerCodes =  types.includes(question.type)
          ? question?.sub_questions.map(sub_question => sub_question.code)
          : question?.answers.map(answer => answer.code)
      let acodeNotTrack = answerCodes.filter(code => !answerCodesRf.includes(code));

      const acodes = Object.keys(result).reduce((acc, key) => {
        const [qcode, , acode] = key.split('_')
        return (parseInt(qcode) === qid && parseInt(result[key]) === 1) ? [...acc, acode] : acc;
      }, [])

      if (types.includes(question.type)) {
        question.sub_questions = question.sub_questions.filter(subQuestion => {
          return logic
              ? [...acodes, ...acodeNotTrack].includes(subQuestion.code)
              : !acodes.includes(subQuestion.code)
        })
      } else {
        question.answers = question.answers.filter(answer => {
          return logic
              ? [...acodes, ...acodeNotTrack].includes(answer.code)
              : !acodes.includes(answer.code)
        })
      }
    })
  }

  return question
}
