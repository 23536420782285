export const GlobalMixin = {
  computed: {
    isAndroid() {
      return /Android/i.test(navigator.userAgent);
    },
    isIos() {
      return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    },
  },
};
