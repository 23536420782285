import axios from '../utils/axios'

const VERSION = 'v1'

const joinPath = (...agrs) => [VERSION, ...agrs].join('/')

export const startSurvey = (surveyCode, params) => {
  return axios.get(joinPath('surveys', surveyCode, 'start'), { params })
}

export const getSurveyFull = (surveyCode, params) => {
  return axios.get(joinPath('surveys', surveyCode, 'full'), { params })
}

export const back = (surveyCode, data) => {
  return axios.post(joinPath('surveys', surveyCode, 'back'), data)
}

export const submit = (surveyCode, data) => {
  return axios.post(joinPath('surveys', surveyCode, 'submit'), data)
}

export const screenOut = (surveyCode, data) => {
  return axios.post(joinPath('surveys', surveyCode, 'screen-out'), data)
}

export const quotaFull = (surveyCode, data) => {
  return axios.post(joinPath('surveys', surveyCode, 'quota-full'), data)
}

export const quotaFullDetail = (surveyCode, questionId) => {
  return axios.get(joinPath('surveys', surveyCode, 'quota-full', questionId))
}

export const getPage = (pid) => {
  return axios.get(joinPath('pages', pid))
}

export const getQuestion = (qid) => {
  return axios.get(joinPath('questions', qid))
}

export const getSurvey = (surveyCode, params) => {
  return axios.get(joinPath('surveys', surveyCode), { params })
}

export const getSurveyPage = (surveyCode, pageCode) => {
  return axios.get(joinPath('surveys', surveyCode, 'pages', pageCode))
}

export const getSurveyQuestion = (surveyCode, questionCode) => {
  return axios.get(joinPath('surveys', surveyCode, 'questions', questionCode))
}

export const getPolicy = (lang) => {
  return axios.get(joinPath('policy'), { params: { lang } })
}

export const login = (surveyCode, data) => {
  return axios.post(joinPath('surveys', surveyCode, 'login'), data)
}

export const getSurveyTemplate = (templateId, params) => {
  return axios.get(joinPath('survey-templates', templateId), { params })
}

export const getSurveyTemplateFull = (templateId, params) => {
  return axios.get(joinPath('survey-templates', templateId, 'full'), { params })
}

export const getSurveyTemplateQuestion = (templateId, questionCode) => {
  return axios.get(joinPath('survey-templates', templateId, 'questions', questionCode))
}

export const getSurveyTemplatePage = (templateId, pageCode) => {
  return axios.get(joinPath('survey-templates', templateId, 'pages', pageCode))
}

export const getTemplateQuestion = (qid) => {
  return axios.get(joinPath('template-questions', qid))
}

export const preview = (surveyCode, params) => {
  return axios.get(joinPath('surveys', surveyCode, 'preview'), { params })
}

export const submitPreview = (surveyCode, data) => {
  return axios.post(joinPath('surveys', surveyCode, 'submit-preview'), data)
}

export const updateResponse = (surveyCode, data) => {
  return axios.post(joinPath('surveys', surveyCode, 'update-response'), data)
}
